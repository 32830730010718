<template>
  <div class="bg-white p-4 form-cont m-auto rounded-3 mb-5 dir-ltr">
    <form :action="redirectURI" class="paymentWidgets"
          :data-brands="dataBrands"></form>
  </div>

  <DonationSuccessDialog :showModal="showDonationSuccessModal"
                         :data="successDonationData"
                         :donationType="donationType"
                         v-if="showDonationSuccessModal"
                         @close="showDonationSuccessModal = false"></DonationSuccessDialog>

  <DonationFailureDialog :showModal="showDonationFailureModal"
                         :message="paymentFailureMessage"
                         v-if="showDonationFailureModal"
                         @close="showDonationFailureModal = false"></DonationFailureDialog>

  <DonationInProgressDialog :showModal="showInProgressModal"
                         :data="progessDonationData"
                         :donationType="donationType"
                         v-if="showInProgressModal"
                         @close="showInProgressModal = false"/>  

  <GiftCardSuccessDialog :showModal="showGiftCardSuccessModal"
                          :message="message"
                          :data="successGiftCardData"
                          v-if="showGiftCardSuccessModal"
                          @close="showGiftCardSuccessModal = false"></GiftCardSuccessDialog>
</template>

<script>
import hyperpayMixin from "@/mixins/hyperpayMixin";
import DonationService from "@/services/donation.service";
import { DONATION_TYPE } from "@/services/enum.service";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import GiftCardSuccessDialog from "@/views/gift-card/GiftCardSuccessDialog";
import GiftCards from "@/services/gift-cards";
import {reactive, defineAsyncComponent} from "vue";
import { getLocalValue, setLocalValue, removeLocalValue } from "@/hooks/useLocalStorage";

const DonationInProgressDialog = defineAsyncComponent(() =>
  import('@/components/donate/DonationInProgressDialog.vue')
)
export default {
  mixins: [hyperpayMixin],
  name: "Checkout",
  components: {DonationFailureDialog, DonationSuccessDialog, GiftCardSuccessDialog, DonationInProgressDialog},
  data() {
    return {
      cardType: "credit_card",
      redirectURI: "",
      showDonationSuccessModal: false,
      successDonationData: {},
      progessDonationData: {},
      successGiftCardData: {},
      message: "",
      donationType: DONATION_TYPE.CASE,
      paymentFailureMessage: "",
      showDonationFailureModal: false,
      showInProgressModal: false,
      showGiftCardSuccessModal: false,
      resourcePath: "",
      paymentTransactionId: 0,
    }
  },
  async mounted() {
    this.cardType = this.$route.query.card_type;
    if (this.$route.query.reference_id) {
      this.isAmbassador = true;
      this.referenceId = this.$route.query.reference_id;
    }

    this.redirectURI = process.env.VUE_APP_FE_URL + `/checkout`;

    if (getLocalValue('gift-card-payment')) {
      removeLocalValue('gift-card-payment')
      this.giftPaymentTransactionId = this.$route.query.id;
      await this.handleGiftCardRedirect()
      return
    }

    if (this.$route.query.payment_type === "gift_card") {
      setLocalValue('gift-card-payment', true)
    }


    if (this.$route.query.id && this.$route.query.resourcePath) {
      this.resourcePath = this.$route.query.resourcePath;
      this.paymentTransactionId = this.$route.query.id;
      this.checkPaymentStatus();
    }
    else {
      setLocalValue('linkOfDonatedObject', window.history.state.back);
    }
  },
  methods: {
    async createGift() {
      try {
        const giftModel = reactive(getLocalValue('giftModel'))
        GiftCards.send({
          "gift_type": giftModel.type,
          "color": giftModel.color.replace('#', ''),
           "checkout_id": this.$route.query.id,
          "specialty": giftModel.speciality,
          "receiver_name": giftModel.receiverName,
          "sender_name": giftModel.senderName,
          "sender_phone_number": giftModel.receiverMobile
        }).then(res => {
          setLocalValue("giftPaymentId", this.giftPaymentTransactionId)
          setLocalValue(this.giftPaymentTransactionId, res)

          this.setGiftCardSuccessData(res)
          removeLocalValue('giftModel')
        }).catch(err => {
          this.paymentFailureMessage = err.response.data.result.message;
          this.showDonationFailureModal = true;
        });
      } catch (e) {}
    },


    async handleGiftCardRedirect() {
      if (this.$route.query.id && this.$route.query.resourcePath) {
        try {
          const { data } = await DonationService.getPaymentStatus(
              this.$route.query.id, this.$route.query.resourcePath
          )
          setLocalValue("paymentStatus", data.status);
          if(data.status===202) {
            await this.createGift()
            return;
          }
          if (data.errors === null) {
            await this.createGift()
          }
        } catch (e) {
          this.paymentFailureMessage = e.response.data.errors[0];
          this.showDonationFailureModal = true;
        }
      }
    },
    checkPaymentStatus() {
      const isPaymentExist = getLocalValue(this.paymentTransactionId.toString())
      if(isPaymentExist) {
        if (getLocalValue('giftPaymentId') === this.paymentTransactionId) {
          if(getLocalValue('paymentStatus') === 200) {
          this.setGiftCardSuccessData(isPaymentExist)
          }
          else if (getLocalValue('paymentStatus') === 202) {
            this.setDonationProgressData(isPaymentExist);
          }
        } else {
          if (getLocalValue('paymentStatus') === 202) {
            this.setDonationProgressData(isPaymentExist);
            this.donationType = isPaymentExist.donation_info.type;
          }
          else if(getLocalValue('paymentStatus') === 200) {
          this.setDonationSuccessData(isPaymentExist)
          }
        }
        return
      }

      DonationService.getPaymentStatus(this.paymentTransactionId, this.resourcePath)
        .then(({ data: { response, status ,errors} }) => {
          setLocalValue("paymentStatus", status);
          if(status === 202) {
              const wallet_id= getLocalValue("wallet");
              DonationService.getPaymentInfo(this.paymentTransactionId, wallet_id)
                .then(( data) => {
                      this.setDonationProgressData(data.data.result);
                      setLocalValue("paymentId", this.paymentTransactionId);
                      setLocalValue(this.paymentTransactionId, this.progessDonationData);
                })
                .finally(() => {
                   removeLocalValue("donationModel");
                   removeLocalValue("wallet"); 
           });
              return;
          }
          const donationModel = getLocalValue("donationModel");
          const postBody = {
            to_wallet: getLocalValue("wallet"),
            amount: donationModel.donation_amount,
            donor_mobile_number: donationModel.doner_mobile,
            checkout_id: this.paymentTransactionId,
            on_behalf_donor_name: donationModel.on_behalf_donor_name,
            gifted_to: donationModel.gifted_to === "أخرى" ? donationModel.gifted_to_text : donationModel.gifted_to,
            donor_relation: donationModel.donor_relation === "أخرى" ? donationModel.donor_relation_text : donationModel.donor_relation,
            gifted_mobile_number: donationModel.gifted_mobile_number,
            on_behalf_donation: donationModel.on_behalf_donation,
            link_of_the_donated_on_object: process.env.VUE_APP_FE_URL + getLocalValue("linkOfDonatedObject")
          };

          DonationService.donate(postBody)
            .then(({ data: { result: { donation_success_data } } }) => {
              this.setDonationSuccessData(donation_success_data)
              setLocalValue("paymentId", this.paymentTransactionId)
              setLocalValue(this.paymentTransactionId, this.successDonationData)
            })
            .finally(() => {
              removeLocalValue("donationModel");
              removeLocalValue("wallet");
            });
        })
        .catch(({ response: { data: { errors } } }) => {
          this.paymentFailureMessage = errors[0];
          this.showDonationFailureModal = true;
        });
    },
    setDonationSuccessData(data) {
      this.successDonationData = data;
      this.donationType = this.successDonationData.type;
      this.showDonationSuccessModal = true;
    },
    setDonationProgressData(data) {
      this.progessDonationData = data;
      this.donationType = this.progessDonationData.donation_info.type
      this.showInProgressModal = true;
    },
    setGiftCardSuccessData(data) {
      this.successGiftCardData = data.data.result.gift_card_success_data;
      this.message = data.data.result.message;
      this.showGiftCardSuccessModal = true;
    }
  },
  unmounted() {
    const getLatestPayment = getLocalValue("paymentId")
    if(getLatestPayment) {
      removeLocalValue("paymentId")
      removeLocalValue(getLatestPayment.toString())
      removeLocalValue("paymentStatus")
    }
    const getLatestGiftPayment = getLocalValue("giftPaymentId")
    if(getLatestGiftPayment) {
      removeLocalValue("giftPaymentId")
      removeLocalValue(getLatestGiftPayment.toString())
    }
  }
};
</script>

<style lang="scss" scoped>
.form-cont {
  width: fit-content;
}

.dir-ltr {
  direction: ltr;
}
</style>
