<template>
  <div class="bg-white p-4 form-cont m-auto rounded-3 mb-5 dir-ltr">
    <form :action="redirectURI" class="paymentWidgets"
          :data-brands="dataBrands"></form>
  </div>

  <DonationSuccessDialog :showModal="showDonationSuccessModal"
                         :data="successDonationData"
                         :donationType="donationType"
                         v-if="showDonationSuccessModal"
                         @close="showDonationSuccessModal = false"></DonationSuccessDialog>

  <DonationInProgressDialog :showModal="showInProgressModal"
                         :data="progessDonationData"
                         :donationType="donationType"
                         v-if="showInProgressModal"
                         @close="showInProgressModal = false"/>  

  <DonationFailureDialog :showModal="showDonationFailureModal"
                         :message="paymentFailureMessage"
                         v-if="showDonationFailureModal"
                         @close="showDonationFailureModal = false"></DonationFailureDialog>
</template>

<script>
import hyperpayMixin from "@/mixins/hyperpayMixin";
import DonationService from "@/services/donation.service";
import { DONATION_TYPE } from "@/services/enum.service";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import { getLocalValue, setLocalValue, removeLocalValue } from "@/hooks/useLocalStorage";
import { defineAsyncComponent } from "vue";
const DonationInProgressDialog = defineAsyncComponent(() =>
  import('@/components/donate/DonationInProgressDialog.vue')
)
export default {
  mixins: [hyperpayMixin],
  name: "Checkout",
  components: {DonationFailureDialog, DonationSuccessDialog , DonationInProgressDialog},
  data() {
    return {
      cardType: "credit_card",
      redirectURI: "",
      showDonationSuccessModal: false,
      successDonationData: {},
      donationType: DONATION_TYPE.CASE,
      paymentFailureMessage: "",
      showDonationFailureModal: false,
      resourcePath: "",
      paymentTransactionId: 0,
      showInProgressModal: false,
      progessDonationData: {},
    }
  },
  mounted() {
    this.cardType = this.$route.query.card_type;
    if (this.$route.query.reference_id) {
      this.isAmbassador = true;
      this.referenceId = this.$route.query.reference_id;
    }

    this.redirectURI = process.env.VUE_APP_FE_URL + `/zakat-checkout`;

    if (this.$route.query.id && this.$route.query.resourcePath) {
      this.resourcePath = this.$route.query.resourcePath;
      this.paymentTransactionId = this.$route.query.id;

      this.checkPaymentStatus();
    }
  },
  methods: {
    checkPaymentStatus() {
      const isPaymentExist = getLocalValue(this.paymentTransactionId.toString())
      if(isPaymentExist) {
        if(getLocalValue("paymentStatus") === 202) {
          this.setDonationProgressData(isPaymentExist);
          return;
        }
        this.setDonationSuccessData(isPaymentExist)
        return
      }
      DonationService.getPaymentStatus(this.paymentTransactionId, this.resourcePath)
        .then(({ data: { response , status, errors} }) => {
          setLocalValue("paymentStatus", status);
          if(status === 202) {
            const wallet_id= getLocalValue("wallet");
              DonationService.getPaymentInfo(this.paymentTransactionId, wallet_id)
                .then(( data) => {
                      this.setDonationProgressData(data.data.result);
                      setLocalValue("paymentId", this.paymentTransactionId);
                      setLocalValue(this.paymentTransactionId, this.progessDonationData);
                })
                .finally(() => {
                   removeLocalValue("donationModel");
                   removeLocalValue("wallet");
           });
           return;
           }
          const donationModel = getLocalValue("donationModel")
          const postBodyZakat = {
            checkout_id: this.paymentTransactionId,
            donor_mobile_number: donationModel.donor_mobile,
          }
          DonationService.payZakat(postBodyZakat)
            .then(({ data: { result: { donation_success_data } } }) => {
              this.setDonationSuccessData(donation_success_data);
              setLocalValue("paymentId", this.paymentTransactionId)
              setLocalValue(this.paymentTransactionId, this.successDonationData)
            })
            .finally(() => {
              removeLocalValue("donationModel")
            });
        })
        .catch(({ response: { data: { errors } } }) => {
          this.paymentFailureMessage = errors[0];
          this.showDonationFailureModal = true;
        });
    },
    handleInProgressStatus(message) {
      this.paymentProgressMessage = message;
      this.showInProgressModal = true;
    },
    setDonationSuccessData(data) {
      this.successDonationData = data;
      this.donationType = this.successDonationData.type;
      this.showDonationSuccessModal = true;
    },
    setDonationProgressData(data) {
      this.progessDonationData = data;
      this.donationType = this.progessDonationData.donation_info.type;
      this.showInProgressModal = true;
    }
  },
  unmounted() {
    const getLatestPayment = getLocalValue("paymentId")
    if(getLatestPayment) {
      removeLocalValue("paymentId")
      removeLocalValue(getLatestPayment.toString())
      removeLocalValue("paymentStatus")
    }
  }
};
</script>

<style lang="scss" scoped>
.form-cont {
  width: fit-content;
}

.dir-ltr {
  direction: ltr;
}
</style>
