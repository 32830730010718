<template>
    <Dialog
        :visible="showModal"
        :modal="true"
        :closable="false"
        class="payment-success-modal p-dialog-share"
        :breakpoints="{'1360px': '50vw', '1024px': '60vw', '960px': '90vw', '640px': '91vw'}" :style="{width: '35vw'}"
        data-cy="payment-success-dialog"
    >
    <template #header>
        <button
          class="p-dialog-header-icon p-dialog-header-close p-link ms-auto"
          type="button"
          @click.prevent="close">
            <i class="p-dialog-header-close-icon pi pi-times"></i>
        </button>
    </template>
    <div class="body">
      <div class="text-center my-4">
        <i class="pi pi-check-circle m-auto color-primary fs-large" v-if="!isInProgress"></i>
        <i class="pi pi-info-circle m-auto text-primary fs-large" v-else></i>

      </div>
      <div class="my-2 text-center color-primary" v-if="$store.getters.isAuthenticated">
        {{ username }}
      </div>
      <div class="color-primary text-center mb-2 fw-bold" data-cy="payment-success-message" v-if="!isInProgress">
        تمت عملية التبرع بنجاح
      </div>
      <div class="color-complementary text-center mb-3 fw-bold" data-cy="thanks-message" v-if="!isInProgress">
        {{ message }}
      </div>
      <div class="text-primary text-center mb-1 fw-bold" v-if="isInProgress">
      تم استلام طلب التبرع   
     </div>
      <div class="text-center mb-3 fw-bold" v-if="isInProgress">
        تم استلام طلب التبرع وهو قيد المعالجة
      </div>
      <div class="color-primary text-center mb-2" data-cy="total-donation-message">
        إجمالي التبرع
      </div>
      <div class="amount-tag rounded m-auto text-center p-1 mb-4 d-flex justify-content-center flex-row-reverse" data-cy="donation-amount">
        <span class="px-1">{{ data.amount }}</span>
        <span>ر.س</span>
      </div>
      <div class="w-75 m-auto">
        <div class="details-table fs-6 mb-4">
          <div class="header d-flex justify-content-between align-items-center flex-row-reverse rounded-4 px-1 px-lg-4 py-1 mb-2" v-if="!isInProgress">
            <div class="mb-2" data-cy="reference-number-label">رقم المرجع</div>
            <div class="text-black fw-bold" data-cy="reference-number">{{ data.reference_number }}</div>
          </div>
          <div class="item d-flex justify-content-between align-items-center flex-row-reverse px-3 py-2">
            <div class="mb-2 text-end" data-cy="date-time-label">التاريخ والوقت</div>
            <div class="text-black fw-bold dir-rtl text-start" data-cy="date-time">{{ data.date }}</div>
          </div>
          <div class="item d-flex justify-content-between align-items-center flex-row-reverse px-3 py-2">
            <div class="mb-2" data-cy="payment-method-label">طريقة الدفع</div>
            <div class="text-black fw-bold" data-cy="payment-method">{{ data.payment_method }}</div>
          </div>
          <div class="item d-flex justify-content-between align-items-center flex-row-reverse px-3 py-2">
            <div class="mb-2" data-cy="donation-specialty-label">تخصص</div>
            <div class="text-black fw-bold" data-cy="donation-specialty">{{ data.specialty }}</div>
          </div>
          <div class="item d-flex justify-content-between align-items-center flex-row-reverse px-3 py-2">
            <div class="mb-2" data-cy="payment-method-label">اسم المهدى له</div>
            <div class="text-black fw-bold" data-cy="payment-method">{{ data.receiver_name }}</div>
          </div>
          <div class="item d-flex justify-content-between align-items-center flex-row-reverse px-3 py-2">
            <div class="mb-2" data-cy="donation-specialty-label">جوال المهدى له</div>
            <div class="text-black fw-bold" data-cy="donation-specialty">{{ data.sender_phone_number }}</div>
          </div>
          <div class="item d-flex justify-content-between align-items-center flex-row-reverse px-3 py-2">
            <div class="mb-2" data-cy="case-id-label">اسم المرسل</div>
            <div class="text-black fw-bold" data-cy="case-id">{{ data.sender_name }}</div>
          </div>
        </div>
        <div class="text-center">
          <button
            id="submit-btn"
            class="btn btn-primary btn-lg submit-btn rounded-5 px-5 text-white"
            type="button"
            @click.prevent="close"
            data-cy="home-page">
            {{ buttonLabel ? buttonLabel :  'الصفحة الرئيسية'}}
          </button>
        </div>
      </div>
    </div>
    </Dialog>
</template>

<script>
export default {
    name: "GiftCardSuccessDialog",
    props: ["message", "data", "showModal"],
    emits: ["close"],
    data() {
    return {
      username: localStorage.getItem("username"),
    };
  },
    computed: {
          isInProgress() {
              return !this.data?.reference_number;
          }
      },
    methods: {
        close() {
            this.$router.push("/");
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.payment-success-modal {
  .amount-tag {
    width: 110px;
    background: #E7F7F7;
    color: $color-primary;
  }

  .input-wrapper {
    width: auto !important;
  }

  .details-table {
    .header {
      background: #ECECEC;
    }

    .item:not(:last-child) {
      border-bottom: 1px solid $color-secondary;
    }
  }

  .submit-btn {
    background: $color-primary;
    border-color: $color-primary;
    transition: all 0.5s;

    &:hover {
      background: $color-primary;
      border-color: $color-primary;
      opacity: 0.9;
    }
  }
}

.color-primary {
  color: $color-primary;
}

.color-complementary {
  color: $color-complementary;
}

.fs-large {
  font-size: 60px;
}

.dir-rtl {
 direction: rtl;
}
</style>